import React from 'react'

function Footer() {
    return (
        <div className='footer'>
             <h5>Contact | kelsea.mcall@gmail.com</h5>
        </div>
    )
}

export default Footer
